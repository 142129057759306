export const learning = [
  { id: 0, class: "dashed", subject: "Styled components" },
  { id: 1, class: "dashed", subject: "Electron" },
  { id: 2, class: "solid", subject: "Express.js" },
  { id: 3, class: "dashed", subject: "React Native" },
  { id: 4, class: "dashed", subject: "C#" },
  { id: 5, class: "dashed", subject: "Unity" },
  { id: 6, class: "solid", subject: "TypeScript" },
  { id: 8, class: "solid", subject: "Next.js" },
  { id: 9, class: "dashed", subject: "D3.js" },
];
